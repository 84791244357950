<template>
  <BModal
    id="modal-package-config"
    size="md"
    centered
    header-bg-variant="light-info"
    :title="$t('packageConfig.agCreate.title')"
    :ok-title="$t('packageConfig.agencyPackageConfigsCreate')"
    :cancel-title="$t('close')"
    cancel-variant="flat-dark"
    :ok-disabled="okDisabled"
    no-close-on-backdrop
    no-close-on-esc
    @show="showHandle"
    @ok="okHandle"
  >
    <div>
      <BRow
        v-for="(config) in agencyPackageConfigToAdd"
        :key="config.id"
      >
        <BCol cols="12">
          <b-form-group
            label-class="h6"
            label-for="agencyCode"
            :label="$t('packageConfig.columns.parentAgency')"
          >
            <v-select
              id="agencyCode"
              v-model="config.agency"
              :options="agencyOptions"
              label="agencyCode"
              searchable
              clearable
              :filterable="false"
              :placeholder="$t('packageConfig.columns.agency')"
              :reduce="val => val"
              :loading="loadingAgencies"
              @open="handleOpenAgency"
              @search="handleSearchAgency"
            >
              <template #selected-option="{agencyName, agencyCode}">
                <div style="width: 100%">
                  <span class="d-block font-weight-bold text-truncate">
                    {{ agencyCode }} <small>({{ agencyName }})</small>
                  </span>
                </div>
              </template>
              <template #option="{agencyName, agencyCode}">
                <div style="width: 100%">
                  <span class="d-block font-weight-bold text-truncate">
                    {{ agencyCode }} <small>({{ agencyName }})</small>
                  </span>
                </div>
              </template>
              <template #spinner="{ loading }">
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
              </template>
              <template #no-options>
                <div
                  v-if="loadingAgencies"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
                <div v-else>
                  {{ $t('noOptions') }}
                </div>
              </template>
            </v-select>
          </b-form-group>
        </BCol>
        <BCol cols="12">
          <b-form-group
            label-class="h6"
            label-for="packageConfig"
            :label="$t('packageConfig.columns.packageConfig')"
          >
            <v-select
              id="packageConfig"
              v-model="config.packageConfig"
              :options="packageConfigOptions"
              label="id"
              searchable
              clearable
              :filterable="false"
              :placeholder="$t('packageConfig.columns.packageConfig')"
              :reduce="val => val"
              :loading="loadingPackageConfigs"
              @open="handleOpenPackageConfig"
              @search="handleSearchPackageConfig"
            >
              <template #selected-option="data">
                <div style="width: 100%">
                  <span class="d-block font-weight-bold text-truncate">
                    {{ data.name }} <small>({{ formatCurrency(data.price) }})</small>
                  </span>
                </div>
              </template>
              <template #option="data">
                <div style="width: 100%">
                  <span class="d-block font-weight-bold text-truncate">
                    {{ data.name }} <small>({{ formatCurrency(data.price) }})</small>
                  </span>
                </div>
              </template>
              <template #spinner="{ loading }">
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
              </template>
              <template #no-options>
                <div
                  v-if="loadingPackageConfigs"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
                <div v-else>
                  {{ $t('noOptions') }}
                </div>
              </template>
            </v-select>
          </b-form-group>
        </BCol>
        <BCol cols="12">
          <b-form-group
            label-class="h6"
            label-for="monthsUse"
            :label="$t('packageConfig.columns.monthsUse')"
          >
            <b-form-input
              id="monthsUse"
              v-model="config.monthsUse"
              v-remove-non-numeric-chars.allNumber
              lazy-formatter
              :placeholder="$t('packageConfig.columns.monthsUse')"
            />
          </b-form-group>
        </BCol>
      </BRow>
    </div>
  </BModal>
</template>
<script>
import {
  BModal, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { ref, computed, nextTick } from '@vue/composition-api'
import { v4 as uuidv4 } from 'uuid'

import { formatCurrency } from '@core/utils/filter'

// import useAgencyPackageConfig from './useAgencyPackageConfig'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect: () => import('vue-select'),
  },
  props: {
    increaseItem: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    // const {
    //   loadingAgencies,
    //   handleOpenAgency,
    //   handleSearchAgency,
    //   agencyOptions,
    //   loadingPackageConfigs,
    //   handleOpenPackageConfig,
    //   handleSearchPackageConfig,
    //   packageConfigOptions,
    //   createAgencyPackageConfig,
    // } = useAgencyPackageConfig()

    const agencyPackageConfigToAdd = ref([])
    const isNotAgencyEnableRetailer = computed(() => agencyPackageConfigToAdd.value[0]?.agency && !agencyPackageConfigToAdd.value[0]?.agency?.enableRetail)
    const okDisabled = computed(() => !agencyPackageConfigToAdd.value.length || agencyPackageConfigToAdd.value.some(item => !item.agency || !item.packageConfig || !Number(item.monthsUse)))

    function pushNewItem() {
      const blankAgencyPackageConfig = {
        agency: props.increaseItem?.agency ?? null,
        packageConfig: null,
        monthsUse: 1,
      }
      agencyPackageConfigToAdd.value.push({ ...blankAgencyPackageConfig, id: uuidv4() })
    }
    function removeItem(id) {
      agencyPackageConfigToAdd.value = agencyPackageConfigToAdd.value.filter(item => item.id !== id)
    }

    function showHandle() {
      agencyPackageConfigToAdd.value = []
      nextTick(() => {
        pushNewItem()
      })
    }
    async function okHandle() {
      // const payload = {
      //   agencyPackageConfigs: agencyPackageConfigToAdd.value.map(item => ({
      //     packageConfigId: item.packageConfig.id,
      //     agencyId: item.agency.id,
      //     monthsUse: Number(item.monthsUse),
      //   })),
      // }
      // await createAgencyPackageConfig(payload)
      // emit('refetchData')
    }
    return {
      agencyPackageConfigToAdd,
      okDisabled,
      showHandle,
      okHandle,
      pushNewItem,
      removeItem,

      formatCurrency,
      isNotAgencyEnableRetailer,
    }
  },
}
</script>
