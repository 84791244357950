var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BModal', {
    attrs: {
      "id": "modal-package-config",
      "size": "md",
      "centered": "",
      "header-bg-variant": "light-info",
      "title": _vm.$t('packageConfig.agCreate.title'),
      "ok-title": _vm.$t('packageConfig.agencyPackageConfigsCreate'),
      "cancel-title": _vm.$t('close'),
      "cancel-variant": "flat-dark",
      "ok-disabled": _vm.okDisabled,
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    },
    on: {
      "show": _vm.showHandle,
      "ok": _vm.okHandle
    }
  }, [_c('div', _vm._l(_vm.agencyPackageConfigToAdd, function (config) {
    return _c('BRow', {
      key: config.id
    }, [_c('BCol', {
      attrs: {
        "cols": "12"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label-class": "h6",
        "label-for": "agencyCode",
        "label": _vm.$t('packageConfig.columns.parentAgency')
      }
    }, [_c('v-select', {
      attrs: {
        "id": "agencyCode",
        "options": _vm.agencyOptions,
        "label": "agencyCode",
        "searchable": "",
        "clearable": "",
        "filterable": false,
        "placeholder": _vm.$t('packageConfig.columns.agency'),
        "reduce": function reduce(val) {
          return val;
        },
        "loading": _vm.loadingAgencies
      },
      on: {
        "open": _vm.handleOpenAgency,
        "search": _vm.handleSearchAgency
      },
      scopedSlots: _vm._u([{
        key: "selected-option",
        fn: function fn(_ref) {
          var agencyName = _ref.agencyName,
            agencyCode = _ref.agencyCode;
          return [_c('div', {
            staticStyle: {
              "width": "100%"
            }
          }, [_c('span', {
            staticClass: "d-block font-weight-bold text-truncate"
          }, [_vm._v(" " + _vm._s(agencyCode) + " "), _c('small', [_vm._v("(" + _vm._s(agencyName) + ")")])])])];
        }
      }, {
        key: "option",
        fn: function fn(_ref2) {
          var agencyName = _ref2.agencyName,
            agencyCode = _ref2.agencyCode;
          return [_c('div', {
            staticStyle: {
              "width": "100%"
            }
          }, [_c('span', {
            staticClass: "d-block font-weight-bold text-truncate"
          }, [_vm._v(" " + _vm._s(agencyCode) + " "), _c('small', [_vm._v("(" + _vm._s(agencyName) + ")")])])])];
        }
      }, {
        key: "spinner",
        fn: function fn(_ref3) {
          var loading = _ref3.loading;
          return [loading ? _c('div', {
            staticClass: "vs__spinner",
            staticStyle: {
              "border-left-color": "rgba(88, 151, 251, 0.71)"
            }
          }) : _vm._e()];
        }
      }, {
        key: "no-options",
        fn: function fn() {
          return [_vm.loadingAgencies ? _c('div', {
            staticClass: "vs__spinner",
            staticStyle: {
              "border-left-color": "rgba(88, 151, 251, 0.71)"
            }
          }) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: config.agency,
        callback: function callback($$v) {
          _vm.$set(config, "agency", $$v);
        },
        expression: "config.agency"
      }
    })], 1)], 1), _c('BCol', {
      attrs: {
        "cols": "12"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label-class": "h6",
        "label-for": "packageConfig",
        "label": _vm.$t('packageConfig.columns.packageConfig')
      }
    }, [_c('v-select', {
      attrs: {
        "id": "packageConfig",
        "options": _vm.packageConfigOptions,
        "label": "id",
        "searchable": "",
        "clearable": "",
        "filterable": false,
        "placeholder": _vm.$t('packageConfig.columns.packageConfig'),
        "reduce": function reduce(val) {
          return val;
        },
        "loading": _vm.loadingPackageConfigs
      },
      on: {
        "open": _vm.handleOpenPackageConfig,
        "search": _vm.handleSearchPackageConfig
      },
      scopedSlots: _vm._u([{
        key: "selected-option",
        fn: function fn(data) {
          return [_c('div', {
            staticStyle: {
              "width": "100%"
            }
          }, [_c('span', {
            staticClass: "d-block font-weight-bold text-truncate"
          }, [_vm._v(" " + _vm._s(data.name) + " "), _c('small', [_vm._v("(" + _vm._s(_vm.formatCurrency(data.price)) + ")")])])])];
        }
      }, {
        key: "option",
        fn: function fn(data) {
          return [_c('div', {
            staticStyle: {
              "width": "100%"
            }
          }, [_c('span', {
            staticClass: "d-block font-weight-bold text-truncate"
          }, [_vm._v(" " + _vm._s(data.name) + " "), _c('small', [_vm._v("(" + _vm._s(_vm.formatCurrency(data.price)) + ")")])])])];
        }
      }, {
        key: "spinner",
        fn: function fn(_ref4) {
          var loading = _ref4.loading;
          return [loading ? _c('div', {
            staticClass: "vs__spinner",
            staticStyle: {
              "border-left-color": "rgba(88, 151, 251, 0.71)"
            }
          }) : _vm._e()];
        }
      }, {
        key: "no-options",
        fn: function fn() {
          return [_vm.loadingPackageConfigs ? _c('div', {
            staticClass: "vs__spinner",
            staticStyle: {
              "border-left-color": "rgba(88, 151, 251, 0.71)"
            }
          }) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: config.packageConfig,
        callback: function callback($$v) {
          _vm.$set(config, "packageConfig", $$v);
        },
        expression: "config.packageConfig"
      }
    })], 1)], 1), _c('BCol', {
      attrs: {
        "cols": "12"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label-class": "h6",
        "label-for": "monthsUse",
        "label": _vm.$t('packageConfig.columns.monthsUse')
      }
    }, [_c('b-form-input', {
      directives: [{
        name: "remove-non-numeric-chars",
        rawName: "v-remove-non-numeric-chars.allNumber",
        modifiers: {
          "allNumber": true
        }
      }],
      attrs: {
        "id": "monthsUse",
        "lazy-formatter": "",
        "placeholder": _vm.$t('packageConfig.columns.monthsUse')
      },
      model: {
        value: config.monthsUse,
        callback: function callback($$v) {
          _vm.$set(config, "monthsUse", $$v);
        },
        expression: "config.monthsUse"
      }
    })], 1)], 1)], 1);
  }), 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }